<script setup lang="ts">
</script>

<template>
  <div>
    <div class="logo-header">
      <nav class="navbar navbar-expand-md fixed-top header-design">
        <div class="logo-position">
          <img :src="require('@/assets/img/suzukilogo.png')" />
        </div>
      </nav>
    </div>
    <div class="sgre-header">
      <div class="title-position fw-bold fs-3 text-light">
        スズキ グレード検索サービス
      </div>
    </div>
  </div>
</template>

<style>
.logo-header {
  color: #000000;
  position: relative;
  width: 100%;
  height: 50px;
  top: 0%;
  display: flex;
  margin-right: 0%;
}
.sgre-header {
  background-color: #0d6efd;
  position: relative;
  width: 100%;
  height: 60px;
  display: flex;
  margin-right: 0%;
  padding-left: 10px;
  vertical-align: middle;
}
.nav-link.active {
  padding: 0%;
  vertical-align: middle;
}
.navbar.navbar-expand-md.fixed-top.header-design {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
.logo-position {
  text-align: left;
  vertical-align: middle;
}
.title-position {
  margin: auto auto auto 0;
}
</style>
