<script lang="ts">
</script>

<template>
  <div>
    <hr />
    <div class="footer mt-1 ms-2">
      (c) SUZUKI MOTOR CORPORATION All rights reserved.
    </div>
  </div>
</template>

<style scoped>
.footer {
  text-align: left;
}
</style>